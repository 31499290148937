import { Field } from 'formik';
import { FieldError } from './FieldError';

const TextInput = ({
  fieldName,
  label,
  annotation = null,
  placeholder = null,
  type = 'input',
  required = true,
  ...rest
}) => {
  return (
    <div className="flex flex-col items-center mb-4">
      <label htmlFor={fieldName} className="font-medium text-gray-900">
        {label}
        {required && <span className="text-red-500 font-bold"> *</span>}
      </label>
      <Field
        as={type}
        name={fieldName}
        id={fieldName}
        className="rounded-md border-2 p-2 w-96"
        placeholder={placeholder || `Enter Your ${label}`}
        autoComplete="off"
        {...rest}
      />
      {annotation && <span className="text-xs text-gray-500 italic">{annotation}</span>}
      <FieldError fieldName={fieldName} />
    </div>
  );
};

export { TextInput };
