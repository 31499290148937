import React from 'react';

const Modal = ({ open, onClose }) => {
  if (!open) {
    return null;
  }
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="fixed inset-0 backdrop-filter backdrop-blur-sm flex items-center justify-center z-50">
        <div className="bg-white rounded-lg shadow-lg p-8">
          <div className="flex justify-end">
            <button
              type="button"
              className="text-gray-500 hover:text-gray-700 transition duration-150"
              aria-label="close modal"
              onClick={onClose}
            >
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <h3 className="text-red-500 text-lg flex flex-col items-center font-semibold mb-4">
            Voucher Redeemed
          </h3>
          <p className="text-gray-700 mb-4">
            This Voucher has already been redeemed. Please enter an un-redeemed voucher number.
            <br />
            Please reach out to us at{' '}
            <a
              href="https://www.leaffilter.com/contact/"
              className="text-blue-500 underline"
              target="_blank"
              rel="noreferrer"
            >
              www.leaffilter.com/contact/
            </a>{' '}
            if you have any questions or concerns.
          </p>
        </div>
      </div>
    </div>
  );
};

export { Modal };
