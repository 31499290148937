import { Form, Formik } from 'formik';
import { useContext } from 'react';
import { FormContext } from '../../App';
import * as yup from 'yup';
import axios from 'axios';
import { env } from '../../env.js';
import { Button, TextInput } from '../../ui';

const customerDetailsSchema = yup.object().shape({
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  emailAddress: yup.string().email('Invalid Email Address'),
  phoneNumber: yup
    .string()
    .matches(/^\d{3}-?\d{3}-?\d{4}$/, 'Invalid Phone Number')
    .required('Phone Number is required'),
});

const CustomerDetails = () => {
  const { activeStepIndex, setActiveStepIndex, formData, setFormData } = useContext(FormContext);

  const handleSubmit = async (values) => {
    const data = { ...formData, ...values };
    setFormData(data);
    setActiveStepIndex(activeStepIndex + 1);
    try {
      await axios.post(env.VITE_POST_CUSTOMER_INFO_URL, data);
    } catch (error) {
      console.error('Error sending email:', error);
    }
  };

  return (
    <Formik
      initialValues={{
        firstName: '',
        lastName: '',
        emailAddress: '',
        phoneNumber: '',
        notes: '',
      }}
      validationSchema={customerDetailsSchema}
      onSubmit={handleSubmit}
    >
      <Form autoComplete="off" className="items-center min-h-screen bg-gray-100">
        <div className="max-w-2xl mx-auto p-6 bg-white rounded shadow">
          <div className="text-left mb-4">
            <p className="text-gray-700 mb-4">
              Thank you for starting the process of redeeming your voucher. You’re almost finished.
            </p>
            <p className="text-gray-700 mb-4">
              To ensure a smoother process for scheduling and completing your gutter service within
              the next 30 days, please provide the additional information below:
            </p>
            <ul className="list-disc pl-8 mb-4">
              <li>Location on Your Home:</li>
              <li>
                Type of Area (Rural/hilly/open field/urban/close proximity to street and/or
                neighbors/many trees/no trees):
              </li>
              <li>Height on home (1 story vs. 2 story or 3 story home):</li>
            </ul>
            <p className="text-gray-700 mb-4">
              Once a voucher is redeemed, please do not attempt to redeem any additional vouchers
              for at least 30 days. By following the steps below and completing this process, you
              are agreeing to have your gutter cleaning scheduled and completed within the next 30
              days. DO NOT click SUBMIT VOUCHER if you are unable to have this service completed
              within the next 30 days at your home. Thank you.
            </p>
            <p className="text-gray-700 mb-4">
              PLEASE follow these steps after completing the above information to complete your
              voucher redemption successfully:
            </p>
            <ol className="list-decimal pl-8 mb-4">
              <li className="text-gray-700 mb-2">SUBMIT by clicking 'SUBMIT VOUCHER' below.</li>
              <li className="text-gray-700 mb-2">
                You will be directed to a confirmation page and receive a confirmation email (If
                email address is provided) that your submission is complete.
              </li>
              <li className="text-gray-700 mb-2">
                Expect our Customer Care Team to contact you within 3 business days.
              </li>
              <li className="text-gray-700 mb-2">
                We will schedule your cleaning with our representatives for a visit to occur within
                30 DAYS of redeeming this voucher.
              </li>
            </ol>
            <p className="text-red-600 font-bold underline mb-4">
              PLEASE NOTE: Once you hit SUBMIT VOUCHER, this action cannot be undone. Please do not
              click SUBMIT VOUCHER unless you are able to have your gutter cleaning completed within
              the next 30 days. Thank you.
            </p>
          </div>
          <TextInput fieldName="firstName" label="First Name" />
          <TextInput fieldName="lastName" label="Last Name" />
          <TextInput
            fieldName="emailAddress"
            label="Email Name"
            annotation="Provide for smoother scheduling"
            required={false}
          />
          <TextInput fieldName="phoneNumber" label="Phone Number" />
          <TextInput fieldName="notes" label="Notes" type="textarea" required={false} />
          <div className="flex justify-center">
            <Button>SUBMIT VOUCHER</Button>
          </div>
        </div>
      </Form>
    </Formik>
  );
};

export { CustomerDetails };
